import axios from 'axios';
import type { Product } from '@interactivevision/products/lib/Api/Models/Product';

export function getSearchHints(search: string) {
  return axios.get<Product[]>(`/api/produkty/szukaj`, {
    params: {
      search,
    },
  }).then(response => response.data);
}
