import classNames          from "classnames";
import {useAtom}           from "jotai";
import {FunctionComponent} from "react";
import React               from "react";
import {atomMenuMobile}    from "../../../atoms/atom-menu-mobile";
import styles              from "./hamburger.module.scss";


const Hamburger: FunctionComponent<{ className: string }> = ({className}) => {
  const [menuMobile, setMenuMobile] = useAtom(atomMenuMobile);

  return (
    <button type="button" className={classNames(styles.hamburger, className, {[styles.active]: menuMobile})} onClick={() => setMenuMobile(!menuMobile)}>
      <span/>
      <span/>
      <span/>
    </button>
  );
};

export default Hamburger;
