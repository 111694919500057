import '../js/cookieconsent.js';
import { FunctionComponent, useEffect } from 'react';
import './cookies.css';


export const Cookies: FunctionComponent = () => {

  useEffect(() => {
    window.CookieConsentApi = window.initCookieConsent();
    window.CookieConsentApi.run({
      current_lang: __i18n_current,
      autoclear_cookies: true,                   // default: false
      page_scripts: true,                        // default: false

      // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
      // delay: 0,                               // default: 0
      auto_language: null,                      // default: null; could also be 'browser' or 'document'
      // autorun: true,                          // default: true
      // force_consent: false,                   // default: false
      // hide_from_bots: false,                  // default: false
      // remove_cookie_tables: false             // default: false
      // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
      // cookie_expiration: 182,                 // default: 182 (days)
      // cookie_necessary_only_expiration: 182   // default: disabled
      // cookie_domain: location.hostname,       // default: current domain
      // cookie_path: '/',                       // default: root
      // cookie_same_site: 'Lax',                // default: 'Lax'
      // use_rfc_cookie: false,                  // default: false
      // revision: 0,                            // default: 0

      // @ts-ignore
      onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once on the first accept/reject action
      },

      // @ts-ignore
      onAccept: function (cookie) {
        // callback triggered on the first accept/reject action, and after each page load
      },

      // @ts-ignore
      onChange: function (cookie, changed_categories) {
        // callback triggered when user changes preferences after consent has already been given
      },

      languages: {
        'pl': {
          consent_modal: {
            title: 'Ciasteczka',
            description: 'Używamy plików cookie na naszej stronie internetowej, aby zapewnić Ci jak najbardziej odpowiednie wrażenia dzięki zapamiętywaniu Twoich preferencji i powtarzaniu wizyt. Klikając „Akceptuję”, wyrażasz zgodę na użycie WSZYSTKICH plików cookie. Możesz jednak odwiedzić Ustawienia plików cookie, aby wyrazić kontrolowaną zgodę. <button type="button" data-cc="c-settings" class="cc-link">Pozwól mi wybrać</button>',
            primary_btn: {
              text: 'Akceptuj',
              role: 'accept_all',              // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Odrzuć',
              role: 'accept_necessary',        // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Preferencje ciasteczek',
            save_settings_btn: 'Zapisz ustawienia',
            accept_all_btn: 'Akceptuj wszystkie',
            reject_all_btn: 'Odrzuć wszystkie',
            close_btn_label: 'Zamknij',
            cookie_table_headers: [
              { col1: 'Nazwa' },
              { col2: 'Domena' },
              { col3: 'Wygasa' },
              { col4: 'Opis' },
            ],
            blocks: [
              {
                title: 'Informacje',
                description: 'Używamy plików cookie na naszej stronie internetowej, aby zapewnić Ci jak najbardziej odpowiednie wrażenia dzięki zapamiętywaniu Twoich preferencji i powtarzaniu wizyt. Po więcej szczegółów udaj się do naszej <a href="/polityka-prywatności" class="cc-link" target="_blank">polityki prywatności</a>.',
              }, {
                title: 'Wymagane ciasteczka',
                description: 'Te ciasteczka są wymagane do poprawnego funkcjonowania strony.',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,          // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              }, {
                title: 'Ciasteczka Analytics',
                description: 'Te pliki cookie umożliwiają witrynie zapamiętanie wyborów dokonanych w przeszłości',
                toggle: {
                  value: 'analytics',     // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [             // list of all expected cookies
                  {
                    col1: '^_ga',       // match all cookies starting with "_ga"
                    col2: 'google.com',
                    col3: '2 lata',
                    col4: 'Zawiera unikalny identyfikator używany przez Google Analytics do określenia, czy dwa różne działania należą do tego samego użytkownika podczas sesji przeglądania.',
                    is_regex: true,
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 dzień',
                    col4: 'Zawiera unikalny identyfikator używany przez Google Analytics do określenia, czy dwa różne działania należą do tego samego użytkownika podczas sesji przeglądania.',
                  },
                ],
              }, {
                title: 'Reklamowe i targetujące pliki cookie',
                description: 'Te pliki cookie zbierają informacje o tym, w jaki sposób użytkownik korzysta z witryny, które strony odwiedza i które linki klika. Wszystkie dane są anonimowe i nie mogą służyć do identyfikacji użytkownika',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '^_fb',       // match all cookies starting with "_ga"
                    col2: 'facebook.com',
                    col3: '3 miesiące',
                    col4: 'Metapiksel to narzędzie analityczne, które pomaga nam mierzyć i zwiększać skuteczność naszych kampanii reklamowych.',
                    is_regex: true,
                  },
                ],
              }, {
                title: 'Więcej informacji',
                description: 'W przypadku jakichkolwiek pytań dotyczących naszej polityki dotyczącej plików cookie i wyborów <a class="cc-link" href="/kontakt">prosimy o kontakt</a>.',
              },
            ],
          },
        },
        'en': {
          consent_modal: {
            title: 'Information',
            description: 'We use cookies on our website to give you the most appropriate experience by remembering your preferences and repeating visits. By clicking "I accept", you consent to the use of ALL cookies. However, you can visit Cookie Settings to provide controlled consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
            primary_btn: {
              text: 'Accept all',
              role: 'accept_all',              // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Reject all',
              role: 'accept_necessary',        // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Cookie preferences',
            save_settings_btn: 'Save settings',
            accept_all_btn: 'Accept all',
            reject_all_btn: 'Reject all',
            close_btn_label: 'Close',
            cookie_table_headers: [
              { col1: 'Name' },
              { col2: 'Domain' },
              { col3: 'Expiration' },
              { col4: 'Description' },
            ],
            blocks: [
              {
                title: 'Cookie usage',
                description: 'We use cookies on our website to give you the most appropriate experience by remembering your preferences and repeating visits.',
              }, {
                title: 'Strictly necessary cookies',
                description: 'These cookies are essential for the proper functioning of our website. Without these cookies, the website would not work properly',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,          // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              }, {
                title: 'Performance and Analytics cookies',
                description: 'These cookies allow the website to remember the choices you have made in the past',
                toggle: {
                  value: 'analytics',     // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [             // list of all expected cookies
                  {
                    col1: '^_ga',       // match all cookies starting with "_ga"
                    col2: 'google.com',
                    col3: '2 years',
                    col4: 'Used to distinguish individual users on our domain.',
                    is_regex: true,
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 day',
                    col4: 'Used to distinguish individual users on our domain.',
                  },
                ],
              }, {
                title: 'Advertisement and Targeting cookies',
                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '^_fb',       // match all cookies starting with "_fb"
                    col2: 'facebook.com',
                    col3: '3 months',
                    col4: 'The Meta pixel is an analytics tool that helps us to measure and increase the effectiveness of our advertising campaigns.',
                    is_regex: true,
                  },
                ],
              }, {
                title: 'More information',
                description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/contact">contact us</a>.',
              },
            ],
          },
        },
        'de': {
          consent_modal: {
            title: 'Informationen',
            description: 'Wir verwenden cookies auf unserer Website, um Ihnen die bestmögliche Erfahrung zu bieten, indem wir uns an Ihre Präferenzen erinnern und Besuche wiederholen. Indem Sie auf „Ich akzeptiere“ klicken, stimmen Sie der Verwendung ALLER cookies zu. Sie können jedoch die Cookie-Einstellungen besuchen, um eine kontrollierte Einwilligung zu erteilen. <button type="button" data-cc="c-settings" class="cc-link">Lass mich aussuchen</button>',
            primary_btn: {
              text: 'Alle akzeptieren',
              role: 'accept_all',              // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Alle ablehnen',
              role: 'accept_necessary',        // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Cookie-Einstellungen',
            save_settings_btn: 'Einstellungen speichern',
            accept_all_btn: 'Alle akzeptieren',
            reject_all_btn: 'Alle ablehnen',
            close_btn_label: 'Close',
            cookie_table_headers: [
              { col1: 'Name' },
              { col2: 'Bereich' },
              { col3: 'Verfallsdatum' },
              { col4: 'Beschreibung' },
            ],
            blocks: [
              {
                title: 'Verwendung von cookies',
                description: 'Wir verwenden cookies auf unserer Website, um Ihnen die bestmögliche Erfahrung zu bieten, indem wir uns Ihre Präferenzen und wiederholten Besuche merken.',
              }, {
                title: 'Streng notwendige cookies',
                description: 'Diese cookies sind für das reibungslose Funktionieren unserer Website unerlässlich. Ohne diese cookies würde die Website nicht richtig funktionieren',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,          // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              }, {
                title: 'Leistungs- und Analyse-cookies',
                description: 'Diese cookies ermöglichen es der Website, sich an die von Ihnen in der Vergangenheit getroffenen Auswahlen zu erinnern',
                toggle: {
                  value: 'analytics',     // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [             // list of all expected cookies
                  {
                    col1: '^_ga',       // match all cookies starting with "_ga"
                    col2: 'google.com',
                    col3: '2 Jahre',
                    col4: 'Wird verwendet, um einzelne Benutzer auf unserer Domain zu unterscheiden.',
                    is_regex: true,
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 Tag',
                    col4: 'Wird verwendet, um einzelne Benutzer auf unserer Domain zu unterscheiden.',
                  },
                ],
              }, {
                title: 'cookies für Werbung und Zielgruppenansprache',
                description: 'Diese cookies sammeln Informationen darüber, wie Sie die Website nutzen, welche Seiten Sie besucht und welche Links Sie angeklickt haben. Alle Daten sind anonymisiert und können nicht verwendet werden, um Sie zu identifizieren',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '^_fb',       // match all cookies starting with "_fb"
                    col2: 'facebook.com',
                    col3: '3 Monate',
                    col4: 'Das Meta-Pixel ist ein Analyseinstrument, das uns hilft, die Wirksamkeit unserer Werbekampagnen zu messen und zu steigern.',
                    is_regex: true,
                  },
                ],
              }, {
                title: 'Weitere Informationen',
                description: 'Bei Fragen zu unserer Politik in Bezug auf cookies und Ihre Wahlmöglichkeiten wenden Sie sich bitte <a class="cc-link" href="/contact"> an uns</a>.',
              },
            ],
          },
        },
        'ru': {
          consent_modal: {
            title: 'Информация',
            description: 'Мы используем файлы cookie на нашем сайте, чтобы предоставить вам наиболее подходящий опыт, запоминая ваши предпочтения и повторяя посещения. Нажимая кнопку "Я принимаю", вы соглашаетесь на использование ВСЕХ файлов cookie. Однако вы можете посетить раздел "Настройки cookie", чтобы предоставить контролируемое согласие. <button type="button" data-cc="c-settings" class="cc-link">Позвольте мне выбрать</button>',
            primary_btn: {
              text: 'Принять все',
              role: 'accept_all',              // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Отклонить все',
              role: 'accept_necessary',        // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Предпочтения в отношении файлов cookie',
            save_settings_btn: 'Сохранить настройки',
            accept_all_btn: 'Принять все',
            reject_all_btn: 'Отклонить все',
            close_btn_label: 'Закрыть',
            cookie_table_headers: [
              { col1: 'Имя' },
              { col2: 'Домен' },
              { col3: 'Срок действия' },
              { col4: 'Описание' },
            ],
            blocks: [
              {
                title: 'Использование файлов cookie',
                description: 'Мы используем файлы cookie на нашем сайте, чтобы предоставить вам наиболее подходящий опыт, запоминая ваши предпочтения и повторяя посещения.',
              }, {
                title: 'Строго необходимые файлы cookie',
                description: 'Эти файлы cookie необходимы для правильного функционирования нашего сайта. Без этих файлов cookie веб-сайт не будет работать должным образом',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,          // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              }, {
                title: 'Файлы cookie, связанные с производительностью и аналитикой',
                description: 'Эти файлы cookie позволяют веб-сайту запомнить выбор, который вы сделали в прошлом',
                toggle: {
                  value: 'analytics',     // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [             // list of all expected cookies
                  {
                    col1: '^_ga',       // match all cookies starting with "_ga"
                    col2: 'google.com',
                    col3: '2 года',
                    col4: 'Используется для различения отдельных пользователей на нашем домене.',
                    is_regex: true,
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 день',
                    col4: 'Используется для различения отдельных пользователей на нашем домене.',
                  },
                ],
              }, {
                title: 'Файлы cookie для рекламы и таргетинга',
                description: 'Эти файлы cookie собирают информацию о том, как вы используете сайт, какие страницы вы посетили и на какие ссылки перешли. Все данные анонимизированы и не могут быть использованы для идентификации вашей личности',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '^_fb',       // match all cookies starting with "_fb"
                    col2: 'facebook.com',
                    col3: '3 месяца',
                    col4: 'Мета-пиксель - это инструмент аналитики, который помогает нам измерять и повышать эффективность наших рекламных кампаний.',
                    is_regex: true,
                  },
                ],
              }, {
                title: 'Дополнительная информация',
                description: 'По любым вопросам, связанным с нашей политикой в отношении файлов cookie и вашим выбором, пожалуйста, <a class="cc-link" href="/kontakt">обращайтесь к нам </a>.',
              },
            ],
          },
        },
        'es': {
          consent_modal: {
            title: 'Información',
            description: 'Utilizamos cookies en nuestro sitio web para ofrecerle la experiencia más adecuada, recordando sus preferencias y repitiendo las visitas. Al hacer clic en "Acepto", usted consiente el uso de TODAS las cookies. Sin embargo, puede visitar Configuración de cookies para dar un consentimiento controlado. <button type="button" data-cc="c-settings" class="cc-link">Déjame elegir</button>',
            primary_btn: {
              text: 'Aceptar todo',
              role: 'accept_all',              // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: 'Rechazar todo',
              role: 'accept_necessary',        // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: 'Preferencias de las cookies',
            save_settings_btn: 'Guardar la configuración',
            accept_all_btn: 'Aceptar todo',
            reject_all_btn: 'Rechazar todo',
            close_btn_label: 'Close',
            cookie_table_headers: [
              { col1: 'Nombre' },
              { col2: 'Dominio' },
              { col3: 'Caducidad' },
              { col4: 'Descripción' },
            ],
            blocks: [
              {
                title: 'Uso de cookies',
                description: 'Utilizamos cookies en nuestro sitio web para ofrecerle la experiencia más adecuada, recordando sus preferencias y repitiendo las visitas.',
              }, {
                title: 'cookies estrictamente necesarias',
                description: 'Estas cookies son esenciales para el correcto funcionamiento de nuestro sitio web. Sin estas cookies, el sitio web no funcionaría correctamente',
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,          // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              }, {
                title: 'cookies de rendimiento y analíticas',
                description: 'Estas cookies permiten que el sitio web recuerde las elecciones que ha hecho en el pasado',
                toggle: {
                  value: 'analytics',     // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [             // list of all expected cookies
                  {
                    col1: '^_ga',       // match all cookies starting with "_ga"
                    col2: 'google.com',
                    col3: '2 años',
                    col4: 'Se utiliza para distinguir a los usuarios individuales en nuestro dominio.',
                    is_regex: true,
                  },
                  {
                    col1: '_gid',
                    col2: 'google.com',
                    col3: '1 día',
                    col4: 'Se utiliza para distinguir a los usuarios individuales en nuestro dominio.',
                  },
                ],
              }, {
                title: 'cookies de publicidad y segmentación',
                description: 'Estas cookies recogen información sobre cómo utiliza el sitio web, qué páginas ha visitado y en qué enlaces ha hecho clic. Todos los datos son anónimos y no pueden utilizarse para identificarle.',
                toggle: {
                  value: 'targeting',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  {
                    col1: '^_fb',       // match all cookies starting with "_fb"
                    col2: 'facebook.com',
                    col3: '3 meses',
                    col4: 'El meta píxel es una herramienta de análisis que nos ayuda a medir y aumentar la eficacia de nuestras campañas publicitarias.',
                    is_regex: true,
                  },
                ],
              }, {
                title: 'Más información',
                description: 'Para cualquier consulta en relación con nuestra política de cookies y sus opciones, <a class="cc-link" href="/contacto">póngase en contacto con nosotros</a>.',
              },
            ],
          },
        },

      },
    });
  }, []);
  return null;
};
