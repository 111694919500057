import { cartAtom } from '../../atoms/cart-atom';
import { useSetAtom } from 'jotai';
import { getCart } from '../../api/web/get-cart';

export const useCartManager = () => {
  const setCart = useSetAtom(cartAtom);

  return {
    refreshCart: () => getCart().then(cart => {
      setCart(cart);
    }),
  };
};
