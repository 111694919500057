import { Base, FontSize, Weight } from './base';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';


type TitleProps = HTMLAttributes<HTMLHeadingElement> & {
  as?: any;
  light?: boolean;
  size?: FontSize;
  weight?: Weight;
}

export const Title = forwardRef<any, PropsWithChildren<TitleProps>>((props, ref) => {
  return <Base as={props.as || 'h2'} light={props.light} {...props} size={props.size || 2} weight={400} ref={ref} className={props.className}>{props.children}</Base>;
});
