import { Photoable } from '../Api/Models/Photoable';
import { Photo } from '../Api/Models/Photo';


export const getPhotoByType = (model: Photoable, type: string): Photo | undefined => {
  if (model) {
    if(!model.photos){
      throw new Error ('There is no photos relation in provided model');
    }
    return model.photos.find((photo: Photo) => photo.type === type);
  }

  return undefined;
};
