import classNames                    from "classnames";
import {FunctionComponent}           from "react";
import React                         from "react";
import {ReactComponent as CloseIcon} from "../assets/icons/close.svg";
import styles                        from "./close.module.scss";


type CloseProps = {
  onClose: () => void;
  className?: string;
}

const Close: FunctionComponent<CloseProps> = ({onClose, className}) => {
  return (
    <button type="button" className={classNames(styles.button, className)} onClick={onClose}>
      <CloseIcon/>
    </button>
  );
};

export default Close;
