import {Link}                     from "@interactivevision/visitor-react";
import classNames                 from "classnames";
import React from "react";
import SectionWrapper             from "./section-wrapper";
import styles                     from "./submenu.module.scss";
import Image from '../../../../../public/static/images/menu.png';

const Submenu = ({items, className}) => {

  const renderSubmenu = (items: any, url: string): any => {
    const count = 6;

    if (items.length > count) {
      return (
        <>
          {items.slice(0, count - 1).map((item, index) => (
            <li key={index}>
              <Link target={item.target} rel={item.rel} href={item.url} title={item.name}>{item.name}</Link>
            </li>
          ))}
          <li>
            <Link href={url} className={styles.more} title={`Więcej: ${items.slice(count).map((element) => (element.name))}`}>Więcej ({items.length - count})</Link>
          </li>
        </>
      );
    }

    return (
      <>
        {items.map((item, index) => (
          <li key={index}>
            <Link target={item.target} rel={item.rel} href={item.url} title={item.name}>{item.name}</Link>
          </li>
        ))}
      </>
    );
  };

  return (
    <div className={classNames(styles.box, className)} style={{backgroundImage: `url(${Image}`}}>
      <SectionWrapper className={styles.wrapper}>
        <div className={styles.content}>
          {
            items && items.map((element, index) => (
              <div className={styles.item} key={`${index}_${element.name}`}>
                <div className={styles.title}>
                  {
                    element.url ? <Link target={element.target} rel={element.rel} href={element.url}><span>{element.name}</span></Link> : <span>{element.name}</span>
                  }
                </div>
                {
                  element.children && (
                    <ul className={styles.list}>
                      {renderSubmenu(element.children, element.url)}
                    </ul>
                  )
                }
              </div>
            ))
          }
        </div>
      </SectionWrapper>
    </div>
  );
};

export default Submenu;
