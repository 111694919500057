import {route}                      from "@interactivevision/routing";
import {Link}                       from "@interactivevision/visitor-react";
import classNames                   from "classnames";
import {FunctionComponent}          from "react";
import React                        from "react";
import styles                       from "./logo.module.scss";
import {ReactComponent as LogoIcon} from "../../../assets/icons/logo.svg";


const Logo: FunctionComponent<{ className?: string; }> = ({className}) => {
  return (
    <Link href={route("homepage_module")} title="Logo Gmyrex Sklep" className={classNames(styles.logo, className)}>
      <LogoIcon/>
    </Link>
  );
};

export default Logo;
