import {route}                       from "@interactivevision/routing";
import React                         from "react";
import styles                        from "./upper.module.scss";
import SectionWrapper                from "../../layout/parialts/section-wrapper";
import {useShared, useSession, Link} from "@interactivevision/visitor-react";
import {ReactComponent as UserIcon}  from "../../assets/icons/user.svg";
import classNames                    from "classnames";


const Upper = () => {
  const shared  = useShared();
  const session = useSession();

  return (
    <div className={styles.box}>
      <SectionWrapper className={styles.wrapper}>
        <div className={classNames(styles.flex, styles.hide)}>
          <span className={styles.item}>Ponad 500 tys. klientów</span>
          <span className={styles.item}>100 dni na darmowy zwrot*</span>
          <span className={styles.item}>Gwarancja najniższej ceny</span>
        </div>
        <div className={styles.flex}>
          {
            shared && (
              <>
                <a href={`mailto:${shared.contact.email}`} title="Kontaktowy adres email" className={classNames(styles.item, styles.contact)}>{shared.contact.email}</a>
                <a href={`tel:${shared.contact.number}`} title="Telefon kontaktowy" className={classNames(styles.item, styles.contact)}>{shared.contact.number}</a>
              </>
            )
          }

          {session.is_authenticated ? (
            <Link href={route("customer.show")} title="Moje konto" className={classNames(styles.item, styles.icon)}>
              <UserIcon/>
              <span>Moje konto</span>
            </Link>
          ) : (
            <Link href={route("login")} title="Zaloguj się" className={classNames(styles.item, styles.icon)}>
              <UserIcon/>
              <span>Zaloguj się</span>
            </Link>
          )}

        </div>
      </SectionWrapper>
    </div>
  );
};

export default Upper;
