import classNames                     from "classnames";
import {FunctionComponent}            from "react";
import {useState}                     from "react";
import React                          from "react";
import {ReactComponent as SearchIcon} from "../../../assets/icons/magnifier.svg";
import {ModalSearch}                  from "../../../modal/modal-search";
import styles                         from "./search.module.scss";


const Search: FunctionComponent<{ className?: string }> = ({className}) => {
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <>
      <div className={classNames(className, styles.search)}>
        <button type="button">
          <SearchIcon onClick={() => setSearchOpen(true)}/>
        </button>
      </div>
      <ModalSearch stickyTop isOpen={searchOpen} setIsOpen={setSearchOpen}/>
    </>
  );
};

export default Search;
