import classNames                                                  from "classnames";
import {useEffect, FunctionComponent, PropsWithChildren, useState} from "react";
import {ReactPortal}                                               from "@interactivevision/core/lib/Components/ReactPortal";
import {useLocation}                                               from "@interactivevision/visitor-react";
import styles                                                      from "./modal.module.scss";


export type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
  stickyTop?: boolean;
}

export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({onClose, isOpen, stickyTop, children}) => {
  const location                            = useLocation();
  const [openedLocation, setOpenedLocation] = useState<string>(location);

  useEffect(() => {
    if (isOpen) {
      setOpenedLocation(location);
      document && document.body.classList.add("scroll-lock");
    } else {
      document && document.body.classList.remove("scroll-lock");
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      if (openedLocation !== location) {
        onClose();
      }
    }
  }, [location]);

  if (!isOpen) {
    return null;
  }

  return (
    <ReactPortal>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={classNames(styles.cell, {[styles.stickyTop]: stickyTop})}>
            {children}
          </div>
        </div>
      </div>
    </ReactPortal>
  );
};
