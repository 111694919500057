import React                            from "react";
import styles                           from "./footer.module.scss";
import {ReactComponent as LogoSvg}      from "../../assets/icons/logo.svg";
import {ReactComponent as EnvelopeIcon} from "../../assets/icons/envelope.svg";
import {ReactComponent as PhoneIcon}    from "../../assets/icons/phone.svg";
import classNames                       from "classnames";
import {Link, useShared}                from "@interactivevision/visitor-react";
import SectionWrapper                   from "./section-wrapper";
import Newsletter                       from "../../components/newsletter";


export default function Footer() {
  const {menu, contact}: any = useShared();

  return (
    <footer className={styles.footer}>
      <Newsletter/>
      <div className={styles.container}>
        <SectionWrapper className={styles.wrapper}>
          <div className={styles.column}>
            <div className={styles.logo}>
              <LogoSvg/>
            </div>
            <div className={styles.menu}>
              {
                menu?.menu_bottom.map((element, index) => (
                  <div className={styles.item} key={index}>
                    <div className={styles.title}>
                      {element.name}
                    </div>
                    {
                      element.children.length > 0 && (
                        <div className={styles.list}>
                          {
                            element.children.map((children, index) => (
                              <div className={styles.element} key={index}>
                                <Link target={children.target} rel={children.rel} href={children.url} title={children.name}>
                                  {children.name}
                                </Link>
                              </div>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </div>
          </div>
          <div className={styles.column}>
            {
              contact && (
                <div className={styles.contact}>
                  {
                    contact.email && (
                      <div className={classNames(styles.line, styles.big)}>
                        <a href={`mailto:${contact.email}`} title="Kontaktowy adres email">
                          <EnvelopeIcon/>
                          <span>{contact.email}</span>
                        </a>
                      </div>
                    )
                  }
                  {
                    contact.number && (
                      <div className={classNames(styles.line, styles.big)}>
                        <a href={`tel:${contact.number}`} title="Telefon kontaktowy">
                          <PhoneIcon/>
                          <span>693 257 347</span>
                        </a>
                      </div>
                    )
                  }
                  <div className={styles.line}>
                    <div className={styles.address}>
                      {contact.street}, {contact.post_code} {contact.city}
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </SectionWrapper>
      </div>
    </footer>
  );
};

