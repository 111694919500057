import FooterSmall from "./parialts/footer-small";
import {Navbar}    from "./parialts/navbar";
import styles      from "./auth-layout.module.scss";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import 'swiper/css/virtual';


export function AuthLayout({children}: any) {
  return (
    <>
      <Navbar/>
      <main className={styles.container}>
        {children}
      </main>
      <FooterSmall/>
    </>
  );
}
