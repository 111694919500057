import React, { FunctionComponent } from 'react';
import { Product } from '@interactivevision/products/lib/Api/Models/Product';
import { useProductData } from '@interactivevision/products/lib/Helpers/use-product-data';
import styles from './product-search.module.scss';
import { Link } from '@interactivevision/visitor-react';
import { route } from '@interactivevision/routing';
import { Picture } from '@interactivevision/media/lib/Components/picture';


export type SearchModalProductProps = {
  product: Product;
};

export const ProductSearch: FunctionComponent<SearchModalProductProps> = ({ product }) => {
  const { title, morph_map, path, model, price_original, price, has_discount } = useProductData(product);

  return (
    <div className={styles.product}>
      <div className={styles.details}>
        {
          morph_map && (
            <div className={styles.image}>
              <Picture photoable={morph_map} sizes={[{ size: '100x100' }]} type="photo" model={model}/>
            </div>
          )
        }
        <Link href={route('product', { path: path })} title={title} className={styles.title}>
          {title}
        </Link>
      </div>
      <div className={styles.prices}>
        <div className={styles.price}>{price}</div>
      </div>
    </div>
  );
};
