import React, { FunctionComponent, PropsWithChildren } from 'react';
import styles from './modal-content.module.scss';
import classNames from 'classnames';


type ModalContentProps = PropsWithChildren & {
  className?: string;
}

const ModalContent: FunctionComponent<ModalContentProps> = ({ children, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      {children}
    </div>
  );
};

export default ModalContent;
