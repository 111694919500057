import React from "react";
import styles from './quantity.module.scss';

const Quantity = ({children}) => {
  return (
    <span className={styles.quantity}>{children}</span>
  );
};

export default Quantity;
