import { ProductType } from '@interactivevision/products/lib/Enums/ProductType';
import { useRemainingInStock } from '@interactivevision/orders/lib/hooks/web/use-remaining-in-stock';
import type { Product } from '@interactivevision/products/lib/Api/Models/Product';


const checkAvailableVariants = (product: Product): boolean => {
  let available = false;

  if (product.variants?.length > 0) {
    product.variants.filter(variant => variant.is_active).forEach(variant => {
      if (useRemainingInStock(variant)) {
        available = true;
      }
    });
  }

  return available;
};

export function useProductData(product) {
  if (product) {
    if (product.type === ProductType.SIMPLE) {
      return {
        title: product.title,
        path: product.variants[0].path,
        morph_map: 'product_variant',
        price: product.variants[0].price.price_gross_formatted,
        has_discount: Math.round(product.variants[0].price.discount_value),
        price_original: product.variants[0].price_original_formatted,
        model: product.variants[0],
        thirty_day_price: '',
        add_to_cart_model: product.variants[0],
        stock_on_order: product.variants[0].stock_on_order,
        stock_available: product.variants[0].stock_available,
        realization_from: product.variants[0].realization_from,
        realization_to: product.variants[0].realization_to,
        files: product.variants[0].files,
      };
    }

    if (product.type === ProductType.CUSTOM) {
      return {
        title: product.title,
        path: product.variants[0].path,
        morph_map: 'product_variant',
        price: product.variants[0].price.price_gross_formatted,
        has_discount: Math.round(product.variants[0].price.discount_value),
        price_original: product.variants[0].price_original_formatted,
        model: product.variants[0],
        thirty_day_price: '',
        add_to_cart_model: product.variants[0],
        stock_on_order: true,
        stock_available: product.variants[0].stock_available,
        realization_from: product.variants[0].realization_from,
        realization_to: product.variants[0].realization_to,
        files: product.variants[0].files,
      };
    }

    if (product.type === ProductType.VARIANT) {
      return {
        title: product.title,
        path: product.path,
        morph_map: 'product',
        price: `od ${product.price_from_formatted}`,
        price_original: product.price_gross_formatted,
        model: product,
        has_discount: Math.round(product.price_from.discount_value),
        thirty_day_price: '',
        add_to_cart_model: product.variants.find(variant => variant.is_active),
        stock_available: checkAvailableVariants(product),
        stock_on_order: false,
        realization_from: product.realization_from,
        realization_to: product.realization_to,

      };
    }

    // variant but for custom product

    if (product.product.type === ProductType.CUSTOM) {
      return {
        title: product.title,
        path: product.path,
        morph_map: 'product_variant',
        price: product.price.price_gross_formatted,
        has_discount: Math.round(product.price.discount_value),
        price_original: product.price_original_formatted,
        model: product,
        thirty_day_price: '',
        add_to_cart_model: product,
        stock_available: product.stock_available,
        stock_on_order: true,
        realization_from: product.realization_from,
        realization_to: product.realization_to,
        files: product.files,
      };
    }

    return {
      title: product.title,
      path: product.path,
      morph_map: 'product_variant',
      price: product.price.price_gross_formatted,
      has_discount: Math.round(product.price.discount_value),
      price_original: product.price_original_formatted,
      model: product,
      thirty_day_price: '',
      add_to_cart_model: product,
      stock_available: product.stock_available,
      stock_on_order: product.stock_on_order,
      realization_from: product.realization_from,
      realization_to: product.realization_to,
      files: product.files,
    };
  }
  return {
    title: undefined,
    path: undefined,
    morph_map: undefined,
    price: undefined,
    has_discount: undefined,
    price_original: undefined,
    model: undefined,
    thirty_day_price: undefined,
    add_to_cart_model: undefined,
    stock_available: undefined,
    stock_on_order: undefined,
    realization_from: undefined,
    realization_to: undefined,
    files: undefined,
  };
}
