import classNames                    from "classnames";
import React, {FunctionComponent}    from "react";
import Close                         from "../../components/close";
import styles                        from "./modal-header.module.scss";


type ModalHeaderProps = {
  title?: string;
  onClose: () => void;
  className?: string;
}

const ModalHeader: FunctionComponent<ModalHeaderProps> = ({title, onClose, className}) => {
  return (
    <div className={classNames(styles.container, className)}>
      {
        title && (
          <div className={styles.title}>
            {title}
          </div>
        )
      }
      <Close onClose={onClose}/>
    </div>
  );
};

export default ModalHeader;
