import React                            from "react";
import styles                           from "./footer-small.module.scss";


export default function FooterSmall() {

  return (
    <footer className={styles.footer}>
      <p>© Copyright 2023 GMYREX SKLEP</p>
    </footer>
  );
};

