import {ReactNode}                from "react";
import React, {FunctionComponent} from "react";
import {Input}                    from "./basic/input";
import {InputProps}               from "./basic/input";
import {FormGroup}                from "./form-group";


export type FormInputProps = InputProps & {
  name: string,
  required?: boolean,
  label?: string | ReactNode,
  error?: string,
  switchable?: boolean,
  switched?: boolean,
  handleSwitched?: () => void;
  light?: boolean
}

export const FormInput: FunctionComponent<FormInputProps> = ({required, label, error, name, light, switchable, switched, handleSwitched, ...props}) => {
  return (
    <FormGroup required={required} name={name} label={label} error={error} switchable={switchable} switched={switched} setSwitched={handleSwitched} id={props.id}>
      <Input name={name} {...props} disabled={props.disabled || (switchable && !switched)}/>
    </FormGroup>
  );
};
