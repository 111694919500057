import {Modal}             from "./modal";
import styles              from "./modal-contact.module.scss";
import {FunctionComponent} from "react";
import ModalContent        from "./partials/modal-content";
import ModalHeader         from "./partials/modal-header";


export const ModalContact: FunctionComponent<{ isOpen: boolean, onClose: () => void }> = ({isOpen, onClose}) => {

  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalContent>
        <ModalHeader onClose={onClose} title="Dziękujemy"/>
        <p className={styles.description}>Twoja wiadomość została wysłana, nasz konsultant skontaktuje się z Tobą w najbliższym czasie</p>
      </ModalContent>
    </Modal>
  );
};
