import {route}                       from "@interactivevision/routing";
import {useSession}                  from "@interactivevision/visitor-react";
import {Link}                        from "@interactivevision/visitor-react";
import classNames                    from "classnames";
import {FunctionComponent}           from "react";
import React                         from "react";
import {ReactComponent as HeartIcon} from "../../../assets/icons/heart.svg";
import styles                        from "./favourites.module.scss";


const Favourites: FunctionComponent<{ className?: string }> = ({className}) => {
  const session = useSession();

  return (
    <div className={classNames(className, styles.favourites)}>
      <Link href={session.is_authenticated ? route("customer.favorites.index") : route("login")} title="Ulubione">
        <HeartIcon/>
      </Link>
    </div>
  );
};

export default Favourites;
