import React, { PropsWithChildren, FunctionComponent } from 'react';
import styles from './form-error.module.scss';

export const FormError: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className={styles.error}>
    <div className={styles.message}>
      {children}
    </div>
  </div>
);
