import { $get } from '@interactivevision/visitor';
import React, { FunctionComponent } from 'react';
import styles from './cart-widget-card.module.scss';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { OrderItem } from '@interactivevision/orders/lib/api/models/order-item';
import { Picture } from '@interactivevision/media/lib/Components/picture';
import { clearCartItem } from '@interactivevision/orders/lib/api/web/clear-cart-item';
import { useCartManager } from '@interactivevision/orders/lib/hooks/web/cart-manager';
import { route } from '@interactivevision/routing';


type Props = {
  item: OrderItem;
  handleClose: () => void;
}

const CartWidgetCard: FunctionComponent<Props> = ({ item, handleClose }) => {
  const { refreshCart } = useCartManager();

  const handleRemove = () => {
    if (window && window.confirm('Czy na pewno chcesz usunąć produkt z koszyka?')) {
      clearCartItem(item.id).then(() => {
        refreshCart();
      });
    }
  };

  const handleNavigate = () => {
    $get(route('product', { path: item.variant?.path })).then(() => handleClose());
  };

  return (
    <div className={styles.container}>
      <div className={styles.image} onClick={handleNavigate}>
        <Picture photoable="product_variant" sizes={[{ size: '300x300' }]} type="photo" model={item.variant}/>
      </div>
      <div className={styles.text}>
        <div>
          <div onClick={item.variant && handleNavigate} className={styles.title}>{item.variant_title}</div>
          <div className={styles.price}>
            <span><b>{item.price_gross_formatted}</b></span>
            <span className={styles.quantity}>x&nbsp;{item.amount}</span>
          </div>
        </div>
        <div className={styles.attributes}>
          {item.external?.combination ? (item.variant?.attributes_values.filter(value => item.external.combination.includes(value.id)).map((value) => (
              <div className={styles.attribute} key={value.id}>
                <span>{value.attribute.name}:</span>
                <span className={styles.value}>{value.content_displayed}</span>
              </div>
            )))

            : (item.variant?.attributes_values.filter(value => value.pivot.is_variant).map((value) => (
              <div className={styles.attribute} key={value.id}>
                <span>{value.attribute.name}:</span>
                <span className={styles.value}>{value.content_displayed}</span>
              </div>
            )))}
        </div>
        <button className={styles.remove} onClick={handleRemove}>
          <TrashIcon/>
        </button>
      </div>
    </div>
  );
};

export default CartWidgetCard;
