import { createElement, ComponentType, useEffect } from 'react';
import { RouterProps } from '@interactivevision/visitor-react';
import { Cookies } from './cookies/components/cookies';
import { useCartManager } from '@interactivevision/orders/lib/hooks/web/cart-manager';

declare var CookieConsentApi: any;
declare var initCookieConsent: any;

type SetupOptions = {
  router: ComponentType<RouterProps>;
  props: RouterProps;
}


export const app = ({ router, props }: SetupOptions) => {
  const { refreshCart } = useCartManager();

  useEffect(() => {
    if (CookieConsentApi) {
      // @ts-ignore
      CookieConsentApi.updateLanguage(globalThis.LocaleDefault, true);
    }

    refreshCart();

  }, []);

  return (
    <>
      {createElement(router, props)}
      <Cookies/>
    </>
  );
};
