import {useAtomValue}   from "jotai";
import React            from "react";
import {atomCartWidget} from "../../atoms/atom-cart-widget";
import {atomMenuMobile} from "../../atoms/atom-menu-mobile";
import CartWidget       from "../../components/cart-widget/cart-widget";
import MenuMobile       from "../../components/navbar/menu-mobile";
import styles           from "./navbar.module.scss";
import Upper            from "../../components/navbar/upper";
import Lower            from "../../components/navbar/lower";


export const Navbar = () => {
  const cartWidget     = useAtomValue(atomCartWidget);

  return (
    <>
      <nav className={styles.navbar} id="navbar">
        <Upper/>
        <Lower/>
      </nav>
      {window && window.innerWidth <= 1100 && <MenuMobile/>}
      {cartWidget && <CartWidget/>}
    </>
  );
};
