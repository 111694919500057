import classNames from 'classnames';
import React, { ReactNode, FunctionComponent } from 'react';
import styles from './section.module.scss';


interface Props {
  dark?: boolean;
  removePaddingTop?: boolean;
  removePaddingBottom?: boolean;
  children: ReactNode;
  className?: string;
  halfPadding?: boolean;
}

const Section: FunctionComponent<Props> = ({ dark, className, removePaddingTop, halfPadding, removePaddingBottom, children }: any) => {
  const classes = classNames(
    styles.section,
    className,
    {
      [styles.dark]: dark,
      [styles.halfPadding]: halfPadding,
      [styles.removePaddingTop]: removePaddingTop,
      [styles.removePaddingBottom]: removePaddingBottom,
    },
  );

  return (
    <section className={classes}>{children}</section>
  );
};

export default Section;
