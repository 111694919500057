import { useAtom } from 'jotai';
import { cartAtom } from '../../atoms/cart-atom';
import { ProductVariant } from '@interactivevision/products/lib/Api/Models/ProductVariant';

// this helper is used for determining how many items left in stock
export function useRemainingInStock(variant?: ProductVariant): number {
  const [cart] = useAtom(cartAtom);

  if (!variant) {
    return 0;
  }

  if (!cart) {
    return variant.stock_available;
  }

  const inCart = cart.items?.find(item => item.variant_id === variant.id);

  if (inCart) {
    return variant.stock_available - inCart.amount;
  }

  return variant.stock_available;
}
