import React, { useState } from 'react';
import SectionWrapper from '../layout/parialts/section-wrapper';
import { Form } from 'react-final-form';
import Section from '../layout/parialts/section';
import styles from './newsletter.module.scss';
import { ReactComponent as EnvelopeIcon } from '../assets/icons/envelope.svg';
import { Base } from '../typography/base';
import { FieldInput } from '../form/fields/field-input';
import { Button } from './button';
import { ModalContact } from '../modal/modal-contact';


const Newsletter = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleSubmit = (values: any) => {
    setModalOpen(true);
  };

  return (
    <>
      <Section className={styles.container}>
        <SectionWrapper className={styles.wrapper}>
          <div className={styles.column}>
            <div className={styles.title}>
              <EnvelopeIcon/>
              <Base as="h3" size={2} weight={500}>Newsletter</Base>
            </div>
            <div className={styles.text}>
              <Base as="p" size={12} weight={200}>
                Zapisz się aby być na bieżąco z aktualnymi promocjami i otrzymać 50 zł zniżki na pierwsze zakupy.
              </Base>
            </div>
          </div>
          <div className={styles.column}>
            <Form onSubmit={handleSubmit}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate className={styles.form}>
                  <FieldInput name="email" placeholder="Twój email" className={styles.input} required/>
                  <Button type="submit">Zapisz się</Button>
                </form>
              )}
            </Form>
          </div>
        </SectionWrapper>
      </Section>
      <ModalContact isOpen={modalOpen} onClose={() => setModalOpen(false)}/>
    </>

  );
};

export default Newsletter;
