import { $get } from '@interactivevision/visitor';
import React, { FunctionComponent, useState } from 'react';
import { Modal } from './modal';
import styles from './modal-search.module.scss';
import { Form, Field } from 'react-final-form';
import { useDebounce } from '@interactivevision/media/lib/Hooks/useDebounce';
import { FormInput } from '../form/form-input';
import { route } from '@interactivevision/routing';
import type { Product } from '@interactivevision/products/lib/Api/Models/Product';
import { getSearchHints } from '../api/search/get-search-hints';
import ModalContent from './partials/modal-content';
import ModalHeader from './partials/modal-header';
import { ProductSearch } from '../components/product/product-search';
import { Loader } from '../components/loader';


export type ModalSearchProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>
  stickyTop?: boolean;
};


export const ModalSearch: FunctionComponent<ModalSearchProps> = ({ isOpen, setIsOpen, stickyTop }) => {
  const [products, setProducts] = useState<Product[]>();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setLoading(false);
  };

  const handleSearch = useDebounce((value) => {
    if (value.length >= 3) {
      setLoading(true);
      getSearchHints(value).then((products) => {
        setProducts(products);
        setLoading(false);
      });
    }
  }, 300);

  const onSubmit = async (values: { search: string }) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.append('search', values.search);
    const queryString = queryParams.toString();

    if (queryString) {
      const url = route('product_module') + '?' + queryString;
      $get(url).then(() => handleClose());
    } else {
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} stickyTop={stickyTop}>
      <ModalContent className={styles.container}>
        <ModalHeader title="Szukaj" onClose={handleClose}/>
        <div>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate className={styles.form} id="search-modal-form">
                <Field name="search" parse={value => {
                  handleSearch(value);
                  return value;
                }
                }>
                  {({ input, meta }) => (
                    <FormInput disabled={loading} placeholder="Wprowadź nazwę produktu..." required light {...input} error={meta.submitError}/>
                  )}
                </Field>
              </form>
            )}
          </Form>

          {loading && <Loader className={styles.loader}/>}

          {products && products.length > 0 && (
            <>
              <div className={styles.products}>
                {products.map((product) => (
                  <ProductSearch product={product} key={product.id}/>
                ))
                }
              </div>
              <div className={styles.seeMoreButton}>Pokaż wszystkie wyniki

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-double-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"/>
                  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </div>
            </>
          )}

          <div className={styles.empty}>
            {products && products.length < 1 && 'Brak wyników'}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
