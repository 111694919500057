import React, { FunctionComponent } from 'react';
import { Field }                    from 'react-final-form';
import {FormInputProps}             from "../form-input";
import {FormInput}                  from "../form-input";


export const FieldInput: FunctionComponent<FormInputProps> = ({ name, ...props }) => {
  return (
    <Field name={name} parse={x => x}>
      {({ input, meta }) => (
        <FormInput {...props} {...input} error={meta.submitError}/>
      )}
    </Field>
  );
};
