import React, { FunctionComponent } from 'react';
import { Photoable } from '../Api/Models/Photoable';
import { Photo } from '../Api/Models/Photo';
import { getPhotoByType } from '../Helpers/GetPhotoByType';
import { getPhotoUrl } from '../Helpers/GetPhotoUrl';

export type SizeType = {
  size: string;
  media?: {
    min?: number | undefined,
    max?: number | undefined,
  }
}
export type PictureProps = {
  photoable: string,
  sizes: Array<SizeType>,
  model?: Photoable,
  type?: string;
  photo?: Photo;
  alt?: string;
  loading?: 'lazy' | 'eager' | undefined;
  fallBack?: string;
}
export const Picture: FunctionComponent<PictureProps> = ({ model, type, photo, sizes, photoable, alt = '', loading = 'lazy', fallBack }) => {
  const image = photo || (model && type && getPhotoByType(model, type));

  if (!image) {
    if (fallBack) {
      return <img src={fallBack} alt="Fallback"/>;
    } else {
      return null;
    }
  }

  const makeMedia = (size: SizeType) => {
    let media = '';

    if (!size.media?.min && !size.media?.max) {
      return '';
    }

    if (size.media?.min) {
      media += `(min-width: ${size.media.min}px)`;

      if (size.media?.max) {
        media += ' and ';
      }
    }

    if (size.media?.max) {
      media += `(max-width: ${size.media.max}px)`;
    }

    return media;
  };

  const makeUrl = (size: string, legacy = true) => {
    let url = getPhotoUrl(photoable, image?.filename || '', size);

    if (!legacy) {
      const dotIndex = url.lastIndexOf('.');

      url = url.substring(0, dotIndex) + '.webp';
    }

    return url;
  };

  return (
    <picture>
      {sizes.map(size => (
        <React.Fragment key={size.size}>
          <source srcSet={makeUrl(size.size, false)}
            media={makeMedia(size)}
            type="image/webp"
          />
          <source srcSet={makeUrl(size.size)}
            media={makeMedia(size)}
            type={image.mime}
          />
        </React.Fragment>
      ))}
      <img src={getPhotoUrl(photoable, image?.filename)} alt={image.alt || alt} loading={loading}/>
    </picture>
  );
};
