import classNames from 'classnames';
import React, { FunctionComponent, ReactNode, ReactElement } from 'react';
import styles from './section-wrapper.module.scss';


interface Props {
  className?: string;
  width?: 'wide' | 'medium' | 'tight',
  children: ReactNode | ReactElement;
}

const SectionWrapper: FunctionComponent<Props> = ({ children, className, width = 'wide' }) => {
  const classes = classNames(
    styles.container,
    className,
    {
      [styles[width]]: width,
    },
  );

  return (
    <div className={classes}>{children}</div>
  );
};

export default SectionWrapper;
