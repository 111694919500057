import {route}                          from "@interactivevision/routing";
import {useLocation}                    from "@interactivevision/visitor-react";
import {useSession}                     from "@interactivevision/visitor-react";
import {Link}                           from "@interactivevision/visitor-react";
import {useShared}                      from "@interactivevision/visitor-react";
import classNames                       from "classnames";
import {useAtom}                        from "jotai";
import {useEffect}                      from "react";
import {useState}                       from "react";
import React, {useRef}                  from "react";
import {ReactComponent as UserIcon}     from "../../assets/icons/user.svg";
import {atomMenuMobile}                 from "../../atoms/atom-menu-mobile";
import Close                            from "../close";
import styles                           from "./menu-mobile.module.scss";
import Logo                             from "./partials/logo";
import Menu                             from "./partials/menu";
import {ReactComponent as PhoneIcon}    from "../../assets/icons/phone.svg";
import {ReactComponent as EnvelopeIcon} from "../../assets/icons/envelope.svg";


const MenuMobile = () => {
  const session                             = useSession();
  const shared                              = useShared();
  const ref                                 = useRef<any>(null);
  const [menuMobile, setMenuMobile]         = useAtom(atomMenuMobile);
  const location                            = useLocation();
  const [openedLocation, setOpenedLocation] = useState<string>(location);

  useEffect(() => {
    setOpenedLocation(location);
  }, [menuMobile]);

  useEffect(() => {
    if (openedLocation !== location) {
      setMenuMobile(false);
    }
  }, [location]);

  return (
    <div className={classNames(styles.container, {[styles.show]: menuMobile})}>
      <Close onClose={() => setMenuMobile(false)} className={styles.close}/>
      <div className={styles.wrapper} ref={ref}>
        <div className={styles.content}>
          <Logo className={styles.logo}/>
          <Menu className={styles.menu}/>
        </div>
        {
          shared && (
            <div className={styles.contact}>
              <a href={`mailto:${shared.contact.email}`} title="Kontaktowy adres email" className={styles.item}>
                <EnvelopeIcon/>
                <span>{shared.contact.email}</span>
              </a>
              <a href={`tel:${shared.contact.number}`} title="Telefon kontaktowy" className={styles.item}>
                <PhoneIcon/>
                <span>{shared.contact.number}</span>
              </a>
              {session.is_authenticated ? (
                <Link href={route("customer.show")} title="Moje konto" className={classNames(styles.item, styles.icon)}>
                  <UserIcon/>
                  <span>Moje konto</span>
                </Link>
              ) : (
                <Link href={route("login")} title="Zaloguj się" className={classNames(styles.item, styles.icon)}>
                  <UserIcon/>
                  <span>Zaloguj się</span>
                </Link>
              )}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default MenuMobile;
