import {createRoot, hydrateRoot}   from "react-dom/client";
import {createElement}             from "react";
import {createVisitor}             from "@interactivevision/visitor-react";
import {AuthLayout}                from "./layout/auth-layout";
import {BaseLayout}                from "./layout/base-layout";
import {app}                       from "./app";
import {Provider as JotaiProvider} from "jotai";


createVisitor({
  resolve: async (name: string) => {
    const components     = import.meta.glob(`./views/**/*.tsx`, {eager: false});
    const component: any = await components[`./views/${name}.tsx`]();

    if (name.includes("auth") || name.includes("panel")) {
      component.default.layout = component.default.layout || AuthLayout;
    } else {
      component.default.layout = component.default.layout || BaseLayout;
    }

    return component;
  },

  setup: (options) => {
    if (import.meta.hot) {
      createRoot(document.getElementById("app") as HTMLElement).render(
        <JotaiProvider>{createElement(app, options)}</JotaiProvider>,
      );
    } else {
      hydrateRoot(document.getElementById("app") as HTMLElement,
        <JotaiProvider>{createElement(app, options)}</JotaiProvider>,
      );
    }
  },
});
